.career h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 3.5rem;
    margin-top: 76px;
}

.careerbox{
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(105, 203, 248, 0.9);
    border-radius: 10px;
    padding: 1.5rem;
}

.careerbutton {
    text-align: center;
}