.limage img{
    width: 100%;
    height: 450px;
}

.content p{
    text-align: justify !important;
    color: #181823;
    font-size: 1.3rem;
    font-weight: 300;
}

.content span{
    font-weight: 600;
}

@media (min-width : 320px) and (max-width : 426px){
    .management{
        padding: 10px;
    }
    img{
        height: 350px;
    }
}

@media (min-width : 427px) and (max-width : 768px){
    
}