.Contact-Us h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 4rem;
}

.form-details form input{
    background-color: #fff;
    border-color: #E5D1FA;
    border-radius: 0px;
    height: 50px;
}

.form-details form textarea{
    height: 100px;
    background-color: #fff;
    border-radius: 0px;
    border-color: #E5D1FA;
}

.contact-button button{
    background-color: #537FE7;
    border-radius: 0px;
    padding: 5px;
    color: #fff;
    font-weight: 300;
    font-size: 1.5rem;
}

@media (min-width : 320px) and (max-width : 426px){
    .contact{
        padding: 10px;
    }
    .Contact-Us h2{
        font-size: 3rem;
    }
}

@media (min-width : 427px) and (max-width : 768px){
    .Contact-Us h2{
        font-size: 3.5rem;
    }
}