.eabout h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 4rem;
}

.eabout h5{
    font-weight: 600;
}
.content p{
    text-align: justify;
    color: #181823;
    font-size: 1.3rem;
    font-weight: 300;
}

.eabout-image img{
    width: 100%;
    height: 500px ;
    border-radius: 20px;
}

@media (min-width : 320px) and (max-width : 426px){
    .ecpm{
        padding: 10px;
    }
    .eabout h2{
        font-size: 3rem;
    }
    .content p{
        font-size: 1rem;
    }
    .ebout-image img{
        height: 300px;
    }
    
  }
  
  @media (min-width : 427px) and (max-width : 768px){
    .eabout-Us h2{
        font-size: 3.5rem;
    }
    .content p{
        font-size: 1rem;
        font-weight: 300;
    }
    .ebout-image img{
        width: 100%;
        height: 500px;
        border-radius: 20px;
    }
  }