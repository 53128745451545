.eproduct-box{
    width: 80%;
    height: 100%;
    box-shadow: 0 0 5px rgba(105, 203, 248, 0.9);
    border-radius: 10px;
}

.eitemswash{
    padding: 1rem;
}
.eitemswash img{
    width: 200px;
    height: 190px;
    margin-left: 3rem;
}

.eitemsgarments{
    padding: 1rem;
}
.eitemsgarments img{
    width: 200px;
    height: 180px;
    margin-left: 3rem;
}

.eitemswash h3{
    font-size: 1.4rem;
    margin-top: 1rem;
    font-weight: 600;
}
.eitemswash h4{
    font-size: 1.4rem;
    margin-top: 1rem;
    font-weight: 600;
}

.eitemsgarments h3{
    font-size: 1.4rem;
    margin-top: 1rem;
    text-align: center;
    margin-top: 2rem;
    font-weight: 600;
}

/* .eitemsgarments h4{
    font-size: 1.4rem;
    margin-top: 1rem;
    font-weight: 600;
} */

@media (min-width : 320px) and (max-width : 426px){
    .eproduct-box{
        width: 100%;
        margin-bottom: 1rem;
    }
    
    .eitemswash{
        padding: 1rem;
    }
    .eitemswash img{
        width: 150px;
        height: 150px;
        margin-left: 3rem;
    }
    
    .eitemswash h3{
        font-size: 1.2rem;
    }

}

@media (min-width : 427px) and (max-width : 768px){
    .eitemswash img{
        margin-left: 1.5rem;
    }
    .eitemswash h3{
        font-size: 1.1rem;
        font-weight: 600;
    }
    .eitemswash h4{
        font-size: 1.1rem;
        font-weight: 600;
    }
}