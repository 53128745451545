.carousel {
  margin-top: 66px;
  position: relative;
}

.carousel .carousel-item img {
  height: 50vh;
}

.carousel .content  {
  
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.carousel .content .head{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  height: 100%;
}

.head h1{
  color: #FFF4D2;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 700;
}

@media (min-width : 320px) and (max-width : 426px){
  .head h1{
    font-size: 1.5rem;
  }
}

@media (min-width : 427px) and (max-width : 768px){
  .head h1{
    font-size: 2.2rem;
  }
}