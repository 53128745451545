img{
    height: 100%;
}

.whatwedo h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 3.5rem;
}

.whatwedo p{
    text-align: justify;
    color: #181823;
    font-size: 1.3rem !important;
    font-weight: 300;
}

.services p{
    text-align: justify;
    color: #181823;
    font-size: 2rem;
    font-weight: 400;
}

.list p{
    font-size: 1.2rem;
    font-weight: 400;
    color: #301E67;
    text-align: left;
}

@media (min-width : 320px) and (max-width : 426px){
    .services p{
        font-size: 1.5rem;
    }
}
