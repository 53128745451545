.About-Us h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 4rem;
}

.content p{
    text-align: justify;
    color: #181823;
    font-size: 1.3rem;
    font-weight: 300;
}

.content p span{
    font-weight: 600;
}

.about-image img{
    width: 100%;
    height: 500px ;
    border-radius: 20px;
}

.point1,.point2,.point3{
    background-color: #ECF2FF;
    width: 100%;
    height: 140px;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}

.point1 span{
    font-weight: 600;
}

.point1{
    padding: 0.5rem;
}
.point2{
    padding: 0.5rem;
}
.point3{
    padding: 1rem;
}
.point1 h5{
    color: #698269;
    margin: 10px;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    padding: 5px;
}

.point2 h5{
    color: #698269;
    margin: 10px;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
}

.point3 h5{
    color: #698269;
    margin: 10px;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    padding: 10px;
}

@media (min-width : 320px) and (max-width : 426px){
    .about{
        padding: 10px;
    }
    .About-Us h2{
        font-size: 3rem;
    }
    .content p{
        font-size: 1rem;
    }
    .image img{
        height: 400px;
    }
    .point1,.point2,.point3{
        height: 160px;
    }
    .point1 h5{
        font-size: 1rem;
        padding: 5px;
    }
    
    .point2 h5{
        font-size: 1rem;
        text-align: center;
        padding: 20px;
        margin-top: 1rem;
    }
    
    .point3 h5{
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        padding: 20px;
        margin-top: 1rem;
    }
  }
  
  @media (min-width : 427px) and (max-width : 768px){
    .About-Us h2{
        font-size: 3.5rem;
    }
    .content p{
        font-size: 1rem;
        font-weight: 300;
    }
    .point1,.point2,.point3{
        height: 160px;
    }
    .image img{
        width: 100%;
        height: 500px;
        border-radius: 20px;
    }
    .point1 h5{
        font-size: 0.9rem;
        padding: 5px;
    }
    
    .point2 h5{
        font-size: 0.9rem;
        padding: 13px;
    }
    
    .point3 h5{
        font-size: 0.9rem;
        padding: 15px;
    }
  }