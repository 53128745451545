.faq h2{
    text-align: center;
    color: #537FE7;
    font-weight: 400;
    font-size: 3rem;
}


@media (min-width : 320px) and (max-width : 426px){
    .faq h2{
        font-size: 2.5rem;
    }

}