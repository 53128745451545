.register{
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgba(105, 203, 248, 0.9);
    border-radius: 20px;
}

.rcontent h5{
    text-align: center;
    color: #537FE7;
    font-size: 2rem;
    font-weight: 600;
}

.register-image img{
    width: 100%;
    height: 600px;
    border-radius: 0px 20px 20px 20px;
}

.form{
    padding-left: 2rem;
    padding-right: 2rem;
}

.button button{
    width: 100% !important;
    font-weight: 500;
    font-size: 1.5rem;
}

.already p{
    text-align: center !important;
    font-size: 1rem;
}


@media (min-width : 320px) and (max-width : 426px){
    .register{
        padding: 10px;
    }
}