.image img{
    width: 100%;
    height: 200px !important;
    border-radius: 0px 0px 15px 15px;
}

.text{
    position: absolute;
    top: 0;
    padding: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    border-radius: 0px 0px 15px 15px;
}

.text h3{
    text-align: center;
    color: #FFF4D2;
    font-size: 2.8rem;
    font-weight: 700;
    margin-top: 10px;
}

.text p{
    text-align: center;
    color: #FFF4D2;
    font-size: 2rem;
    font-weight: 400;
}

@media (min-width : 320px) and (max-width : 426px){
    .text h3{
        font-size: 1.5rem;
    }
    .text p{
        font-size: 1rem;
    }
}

@media (min-width : 427px) and (max-width : 768px){

}