.footer{
    background-color: #001D6E;
}

.footer-about h2{
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}

.footer-about p{
    color: #fff;
    text-align: justify;
    font-size: 1rem;
    margin-top: 20px;
    width: 90%;
}

.footer-categories h2{
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}

.footer-categories p{   
    text-align: justify;
    font-size: 1rem;
    margin-top: 20px;
}

.footer-categories a{
    text-decoration: none;
    color: #fff;
    display :block
}

.quick-links h2{
    color: #fff;
    font-weight: 700;
    font-size: 1.5rem;
}

.quick-links a{
    text-decoration: none;
    color: #fff;
    display: block;
}
hr{
    color: wheat;
}

.copy-rights p{
    text-align: justify;
    font-size: 1rem;
    color: #fff;
}

.social-medias{
    margin-left: 70%;
}

.social-medias a{
    padding: 10px;
    color: #fff;
}

@media (min-width : 320px) and (max-width : 426px){
    .footer-about p{
        width: 100%;
    }

    .copy-rights p{
        font-size: 0.8rem;
    }

    .social-medias{
        margin-left: 25%;
    }
}

@media (min-width : 427px) and (max-width : 768px){
    .footer-about p{
        width: 100%;
    }

    .copy-rights p{
        font-size: 0.8rem;
    }

    .social-medias{
        margin-left: 40%;
    }

}