img{
    height: 400px;
}

.lpoints h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 3.5rem;
}

.lpoints p{
    text-align: justify;
    color: #181823;
    font-size: 1.5rem;
    font-weight: 300;
}

.lpoints span{
    font-weight: 400;
}

@media (min-width : 320px) and (max-width : 426px){
    .lgoals{
        padding: 10px;
    }
}