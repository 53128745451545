/* .btbox{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #ECF9FF;
} */

.btable{
    padding: 1rem;
    background-color: #fff;
}

Form.control{
    width: 50%;
}

/* .btfirm-name{
    padding: 1rem;
}

.btname{
    padding: 1rem;
}

.btnumber{
    padding: 1rem;
}

.btemail{
    padding: 1rem;
}

.btaddress{
    padding: 1rem;
}

.btcompany-type{
    padding: 1rem;
} */
