.login {
  margin-left: 1px;
}

.login Nav.Link {
  color: #fff;
}

.logo img {
  width: 120px;
  height: 50px;
}

.navlink a {
  text-decoration: none;
  padding: 1rem;
  color: var(--bs-nav-link-color);
}

.model {
  padding: 1rem;
}

.model-title {
  text-align: left;
  font-weight: 600;
  font-size: 1.5rem;
  color: #3e54ac;
}

.logimage img {
  width: 100%;
  height: 330px;
}

.logbutton {
  width: 100%;
  font-weight: 600;
  font-size: 1.3rem;
  padding: 10px 20px;
}

.forget-password a{
  text-align: right;
}

.sign-up p{
  text-align: center;
}

.app-navbar .navdropdown a:focus{
  color: #fff;
}



@media (min-width: 320px) and (max-width: 426px) {
  .login {
    margin-bottom: 0.5rem;
  }
  .navbar {
    padding: 1rem;
  }

  .navdropdown {
    font-size: 0.9rem;
  }
  .login a {
    width: 100%;
    margin-top: 1rem;
  }
  .Register a {
    width: 100%;
  }
}

@media (min-width: 427px) and (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navdropdown {
    font-size: 1rem;
  }
  .login a {
    width: 100%;
    margin-top: 1rem;
  }
  .Register a {
    width: 100%;
    margin-top: 0.5rem;
  }
}
