.business{
    width: 100%;
    height: 200px;
    padding: 1rem;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
    border-radius: 40px 0px 0px 0px;
}
.software{
    width: 100%;
    height: 200px;
    padding: 1rem;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}
.legal{
    width: 100%;
    height: 200px;
    padding: 1rem;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}
.wellness{
    width: 100%;
    padding: 1rem;
    height: 200px;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}

.e-com{
    width: 100%;
    padding: 1rem;
    height: 200px;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
    border-radius: 0px 0px 40px 0px;
}


.business .icon{
    text-align: center;
    font-size: 2rem;
    padding-top: 0.5rem;
    color: #7DB9B6;
}

.business .head h3{
    text-align: center;
    font-size: 1.3rem;
    padding-top: 1rem;
    color: #7DB9B6;
    font-weight: 600;
}

.software .icon{
    text-align: center;
    font-size: 2rem;
    padding-top: 1.3rem;
    color: #7DB9B6;
}

.software .head h3{
    text-align: center;
    font-size: 1.3rem;
    padding-top: 2rem;
    color: #7DB9B6;
    font-weight: 600;
}

.legal .icon{
    text-align: center;
    font-size: 2rem;
    padding-top: 1.3rem;
    color: #7DB9B6;
}

.legal .head h3{
    text-align: center;
    font-size: 1.3rem;
    padding-top: 2rem;
    color: #7DB9B6;
    font-weight: 600;
}

.wellness .icon{
    text-align: center;
    font-size: 2rem;
    padding-top: 1.3rem;
    color: #7DB9B6;
}

.wellness .head h3{
    text-align: center;
    font-size: 1.3rem;
    padding-top: 2rem;
    color: #7DB9B6;
    font-weight: 600;
}

.e-com .icon{
    text-align: center;
    font-size: 2rem;
    padding-top: 1.3rem;
    color: #7DB9B6;
}

.e-com .head h3{
    text-align: center;
    font-size: 1.3rem;
    padding-top: 2rem;
    color: #7DB9B6;
    font-weight: 600;
}

@media (min-width : 320px) and (max-width : 426px){
    .category{
        padding: 10px;
    }
    .business{
        height: 150px;
    }
    .software{
        height: 150px;
        margin-top: 1rem;
    }
    .legal{
        height: 150px;
        margin-top: 1rem;
    }
    .wellness{
        height: 150px;
        margin-top: 1rem;
    }
    .e-com{
        height: 150px;
        margin-top: 1rem;
    }

    .business .icon{
        padding-top: 0.3rem;
    }
    
    .business .head h3{
        padding-top: 0.5rem;
    }
    
    .software .icon{
        padding-top: 0.5rem;
    }
    
    .software .head h3{
        padding-top: 1rem;
    }
    
    .legal .icon{
        padding-top: 0.5rem;
    }
    
    .legal .head h3{
        padding-top: 1rem;
    }
    
    .wellness .icon{
        padding-top: 0.5rem;
    }
    
    .wellness .head h3{
        padding-top: 1rem;
    }
    
    .e-com .icon{
        padding-top: 0.5rem;
    }
    
    .e-com .head h3{
        padding-top: 1rem;
    }
}

@media (min-width : 427px) and (max-width : 768px){

    
    .e-com{
        margin-top: 1rem;
    }

}