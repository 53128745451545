.box{
    width: 100%;
    height: 100%;
    box-shadow: 0 0 16px rgba(105, 203, 248, 0.5);
    border-radius: 10px;
}

.swhatwedo h2{
    text-align: center;
    color: #537FE7;
    font-weight: 400;
    font-size: 3.5rem;
    padding: 10px;
}

.whatwedo p{
    margin-left: 1rem;
    text-align: justify;
    color: #181823;
    font-size: 1.7rem;
    font-weight: 400;
}

.slist p{
    font-size: 1.2rem;
    font-weight: 400;
    color: #301E67;
    text-align: left;
}

.slist span{
    font-size: 1.2rem;
    font-weight: 600;
    color: #000;
    
}

@media (min-width : 320px) and (max-width : 426px){
    .head h2{
        font-size: 3rem;
    }
}
@media (min-width : 427px) and (max-width : 768px){

}