.products h2{
    text-align: left;
    color: #537FE7;
    font-weight: 400;
    font-size: 4rem;
}
.product-box{
    width: 80%;
    height: 100%;
    box-shadow: 0 0 5px rgba(105, 203, 248, 0.9);
    border-color: #537FE7;
    border-radius: 10px;
}

.items{
    padding: 1rem;
}
.items img{
    width: 210px;
    height: 200px;
    margin-left: 3rem;
}

.items h3{
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

.items h4{
    font-size: 1.4rem;
    margin-top: 0.5rem;
    font-weight: 600;
    margin-top: 0.5rem;
}

@media (min-width : 320px) and (max-width : 426px){
    .product-box{
        width: 100%;
        margin-bottom: 1rem;
    }
    
    .items{
        padding: 1rem;
    }
    .items img{
        width: 150px;
        height: 150px;
        margin-left: 3rem;
    }
    
    .items h3{
        font-size: 1.2rem;
    }
    
    .items button{
        margin-left: 5rem;
    }

}

@media (min-width : 427px) and (max-width : 768px){
    .items img{
        margin-left: 1.5rem;
    }
    .items h3{
        font-size: 1.1rem;
        font-weight: 600;
    }
    .items h4{
        font-size: 1.1rem;
        font-weight: 600;
    }
    .items button{
        margin-left: 4rem;
    }

}